import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="booking--resend-email"
export default class extends Controller {

  static targets = ["email", "resendCheckbox"]

  connect() {
    console.log(this.identifier);
    this.manuallyUnchecked = false
  }

  emailChanged() {
    if (!this.hasResendCheckboxTarget || this.manuallyUnchecked) {
      return
    }

    this.resendCheckboxTarget.checked = true
  }

  resendCheckboxChanged(event) {
    if (!event.target.checked) {
      this.manuallyUnchecked = true
    }
  }
}
